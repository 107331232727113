import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import Masthead from '../components/masthead/masthead'
import Tour from '../components/tour'
import TourItem from '../components/tourItem/tourItem'
import Cta from '../components/cta/cta'
import EmailForm from '../components/emailForm/emailForm'

const IndexPage = () => (
  <Layout modifiers={[`white`]}>
    <SEO title="Home" keywords={[`personal finance`, `fintech`, `ASEAN`]} />
    <section className={'c-masthead c-masthead--split'}>
      <div className="c-masthead__overlay">
        <div className="o-block c-masthead__block">
          <div className="o-block__container o-block__container--6 c-masthead__container">
            <div className="o-title o-title--large c-masthead__title">
              <div className="o-inner">
                <h2 className="o-title__headline c-masthead__headline">Financial freedom for all.</h2>
              </div>
            </div>
          </div>
          <div className="o-block__container o-block__container--6 c-masthead__container">
            <div className="o-title c-masthead__title">
              <div className="o-inner">
                <h2 className="o-title__headline c-masthead__headline"><em>Domestic worker?</em><br/>OFW ka ba?</h2>
                <span className="o-sp__2"></span>
                <a className="o-button o-button--chevron" href="https://www.facebook.com/joinPilot" target="_blank"><span className="o-button__label">Go to Facebook</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="o-section">
      <div className="o-block o-block--justify">
        <div className="o-block__container o-block__container--7">
          <div className="o-title">
            <div className="o-inner">
              <h2 className="o-title__headline">About us</h2>
              <p className="o-title__byline">pilot is unlocking Asia's migrant workers' economic potential with honest, transparent financial services.</p>
              <p className="o-title__byline">Through an obsessive understanding of their lives and financial needs, we're building uplifting and empowering experiences that fix financial services for the world's most financially vulnerable.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="o-section">
      <div className="o-block o-block--justify">
        <div className="o-block__container o-block__container--7">
          <div className="o-title">
            <div className="o-inner">
              <h2 className="o-title__headline">Join the mission</h2>
              <p className="o-title__byline">We're currently in stealth mode. Drop us a line if you’d like to learn more.</p>
            </div>
          </div>
          <div className="o-sp__2"></div>
          <EmailForm linkTo={`https://formspree.io/hello@joinpilot.co`} linkText={`Submit`}></EmailForm>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
